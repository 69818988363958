import { z, ZodIssue, ZodSchema } from "zod";

function indent(txt: string, spaces: number) {
  const lines = txt.split("\n");
  if (lines.length < 2) {
    return lines.join("\n");
  }

  const prefix = " ".repeat(spaces);
  return [lines[0], ...lines.slice(1).map((l) => `${prefix}${l}`)].join("\n");
}

export function formatZodIssue(issue: ZodIssue): string {
  if (issue.code === "too_big" && issue.type === "string") {
    const desc = issue.exact
      ? `exactly`
      : issue.inclusive
        ? `at most`
        : `under`;
    return `Must contain ${desc} ${issue.maximum} character(s)`;
  }

  if (issue.code === "too_small" && issue.type === "string") {
    const desc = issue.exact
      ? "exactly"
      : issue.inclusive
        ? `at least`
        : `over`;
    return `Must contain ${desc} ${issue.minimum} character(s)`;
  }

  if (issue.code === "invalid_union") {
    const list = issue.unionErrors
      .map((err, i) => {
        const prefix = `  ${i + 1}:`;
        return `${prefix} ${indent(
          formatZodIssue(err.issues[0]),
          prefix.length,
        )}`;
      })
      .join("\n");

    return `Value does not match any of the allowed types:\n${list}`;
  }

  if (issue.code === "invalid_type" && issue.received === "null") {
    return `Required`;
  }

  return issue.path.length
    ? `${issue.path.join(".")}: ${issue.message}`
    : issue.message;
}

const isObj = (v: unknown): v is Record<string, unknown> =>
  !!(v && typeof v === "object");
const get = (v: unknown, key: string) => (isObj(v) ? v[key] : undefined);
const getDefault = (schema: ZodSchema<any>) => {
  const r = schema.safeParse(undefined);
  return r.success ? r.data : undefined;
};

export function fillDefaults(schema: ZodSchema<any>, value?: unknown): any {
  if (schema instanceof z.ZodEffects) {
    return fillDefaults(schema.innerType(), value);
  }

  if (schema instanceof z.ZodOptional) {
    return value === undefined
      ? undefined
      : fillDefaults(schema.unwrap(), value);
  }

  if (schema instanceof z.ZodObject) {
    return Object.fromEntries(
      Object.entries(schema.shape as Record<string, ZodSchema<any>>).map(
        ([key, s]) => {
          return [key, fillDefaults(s, get(value, key))];
        },
      ),
    );
  }

  if (schema instanceof z.ZodArray) {
    return (Array.isArray(value) ? value : []).map((v) =>
      fillDefaults(schema.element, v),
    );
  }

  if (schema instanceof z.ZodLiteral) {
    return schema.value;
  }

  if (schema instanceof z.ZodDefault) {
    return value === undefined
      ? getDefault(schema)
      : fillDefaults(schema.removeDefault(), value);
  }

  if (schema instanceof z.ZodUnion) {
    const type = get(value, "type");
    if (!type) {
      return value;
    }

    const unionOfObjectsWithLiteralType = schema.options.every(
      (s: unknown) =>
        s instanceof z.ZodObject &&
        get(s.shape, "type") instanceof z.ZodLiteral,
    );

    if (!unionOfObjectsWithLiteralType) {
      return value;
    }

    const match = schema.options.find(
      (s: z.ZodObject<{ type: z.ZodLiteral<string> }>) =>
        s.shape.type.value === type,
    );

    if (!match) {
      return value;
    }

    return fillDefaults(match, value);
  }

  return value;
}
