import React from "react";
import classnames from "classnames";

import { TextSkeleton } from "../Skeleton";

import { BasicPagination, type AlignOptions } from "./index";
import styles from "./index.module.less";

interface SkeletonColumn {
  header: React.ReactNode;
  headerClassName?: string;
  cellClassName?: string | ((row: any) => string | undefined);
  colClassName?: string;
  align?: "left" | "right" | AlignOptions<any>;
}

type Props = {
  numRows?: number;
  noBottomBorder?: boolean;
  containerClassName?: string;
  basicPagination?: any;
} & (
  | {
      numColumns: number;
    }
  | {
      columnNames: (string | (() => React.ReactNode))[];
    }
  | {
      columns: SkeletonColumn[];
    }
);

const align = (col: SkeletonColumn) =>
  col.align === "right" ? styles.right : styles.left;

export function TableSkeleton(props: Props) {
  const numRows = props.numRows || 5;

  const columns: SkeletonColumn[] =
    "columns" in props
      ? props.columns
      : "columnNames" in props
        ? props.columnNames.map((t) => ({ header: <>{t}</> }))
        : Array(props.numColumns)
            .fill(0)
            .map((_, i) => ({ header: <TextSkeleton key={i} /> }));

  return (
    <div
      className={classnames(props.containerClassName, styles.tableContainer)}
    >
      <table className={styles.table}>
        <colgroup>
          <col />
          {columns.map((c, i) => (
            <col className={classnames(align(c), c.colClassName)} key={i} />
          ))}
          <col />
        </colgroup>
        <thead>
          <tr>
            <th />
            {columns.map((c, i) => (
              <th key={i}>
                <div
                  className={classnames(
                    styles.header,
                    align(c),
                    c.headerClassName,
                  )}
                >
                  {c.header}
                </div>
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody
          className={classnames({
            [styles.noBottomBorder]: props.noBottomBorder,
          })}
        >
          {new Array(numRows).fill(0).map((_, i) => {
            return (
              <tr key={i}>
                <td />
                {columns.map((c, j) => {
                  return (
                    <td
                      key={j}
                      className={classnames(
                        typeof c.cellClassName === "string"
                          ? c.cellClassName
                          : undefined,
                      )}
                    >
                      <div
                        className={classnames(
                          "flex",
                          align(c),
                          align(c) === styles.right && "justify-end",
                          styles.cellContainer,
                        )}
                      >
                        <TextSkeleton />
                      </div>
                    </td>
                  );
                })}
                <td />
              </tr>
            );
          })}
        </tbody>
      </table>
      {props.basicPagination && <BasicPagination />}
    </div>
  );
}
