import { InvoiceStatusEnum } from "types/generated-graphql/__types__";
import { createContainer } from "unstated-next";

type BMGroupKeyOverridePayload = Array<{
  group_key_name: string;
  display_name?: string;
  value_display_names?: {
    [group_key_value: string]: string;
  };
}>;

type BMGroupKeyOverrides = {
  [key: string]: {
    display_name?: string;
    value_display_names?: {
      [key: string]: string;
    };
  };
};

function convertPayloadToOverridesMap(
  payload: BMGroupKeyOverridePayload,
): BMGroupKeyOverrides {
  if (!payload || !Array.isArray(payload)) {
    return {};
  }

  return payload.reduce((map, item) => {
    map[item.group_key_name] = {
      display_name: item.display_name,
      value_display_names: item.value_display_names,
    };
    return map;
  }, {} as BMGroupKeyOverrides);
}

function getDisplayName(overrides: BMGroupKeyOverrides, key: string): string {
  const override = overrides[key];
  return override && override.display_name ? override.display_name : key;
}

function getValueDisplayName(
  overrides: BMGroupKeyOverrides,
  key: string,
  value: string | null,
): string | null {
  const override = overrides[key];
  return value &&
    override &&
    override.value_display_names &&
    override.value_display_names[value]
    ? override.value_display_names[value]
    : value;
}

function useEmbeddableDashboardContext(
  options: { isEmbeddableDashboard: boolean } = {
    isEmbeddableDashboard: false,
  },
) {
  let statuses: InvoiceStatusEnum[] | null = null;
  if (window.location.search.includes("hide_voided_invoices=")) {
    const urlParams = new URLSearchParams(window.location.search);
    if ((urlParams.get("hide_voided_invoices") ?? "false") === "true") {
      statuses = [
        InvoiceStatusEnum.Draft,
        InvoiceStatusEnum.Finalized,
        InvoiceStatusEnum.FinalizedInOldSystem,
        InvoiceStatusEnum.PendingFinalization,
      ];
    }
  }

  let showChargesWithZeroUsage = false;
  if (window.location.search.includes("show_zero_usage_line_items=")) {
    const urlParams = new URLSearchParams(window.location.search);
    try {
      const showZeros =
        (urlParams.get("show_zero_usage_line_items") || "false") === "true";
      showChargesWithZeroUsage = showZeros;
    } catch {
      // do nothing
    }
  }

  let hideGrantName = false;
  if (window.location.search.includes("hide_grant_name=")) {
    const urlParams = new URLSearchParams(window.location.search);
    try {
      const hideName = (urlParams.get("hide_grant_name") || "false") === "true";
      hideGrantName = hideName;
    } catch {
      // do nothing
    }
  }

  let bmGroupKeyOverrides: BMGroupKeyOverrides = {};
  if (window.location.search.includes("bmGroupKeyOverrides=")) {
    const overrides = new URLSearchParams(window.location.search).get(
      "bmGroupKeyOverrides",
    );
    if (overrides) {
      try {
        const data = JSON.parse(overrides) as BMGroupKeyOverridePayload;
        bmGroupKeyOverrides = convertPayloadToOverridesMap(data);
      } catch {
        // do nothing
        bmGroupKeyOverrides = {};
      }
    }
  }

  return {
    isEmbeddableDashboard: options.isEmbeddableDashboard,
    showChargesWithZeroUsage,
    statuses,
    hideGrantName,
    getGroupDisplayName: (key: string) =>
      getDisplayName(bmGroupKeyOverrides, key),
    getGroupValueDisplayName: (key: string, value: string | null) =>
      getValueDisplayName(bmGroupKeyOverrides, key, value),
  };
}

export const EmbeddableDashboardContext = createContainer(
  useEmbeddableDashboardContext,
);
