import { z } from "zod";

import { formatZodIssue, fillDefaults } from "./Zod";

export interface Form<S extends Form.Shape> {
  valid?: S;
  schema: Form.Schema<any>;
  fields: Form.Fields<S>;
  children: Record<string, Form.Child<any, S>>;
  rootLevelErrors?: string[];
}

export namespace Form {
  export interface Child<S extends Form.Shape, PS extends Form.Shape>
    extends Form<S> {
    read(parent: Form<PS>): Form.Update<S>;
    write(self: Form<S>, parent: Form<PS>): Form.Update<PS>;
  }

  export type Shape = Zod.ZodRawShape;

  export type Key<S extends Shape> = keyof S & string;
  export type Update<S extends Shape> =
    | {
        [K in Key<S>]?: z.infer<S[K]>;
      }
    | {
        [K in Key<S>]?: z.infer<S[K]> | any;
      };
  export type Values<S extends Shape> = {
    [K in Key<S>]: z.infer<S[K]>;
  };
  export type Value<S extends Shape, K extends Key<S>> = z.infer<S[K]>;

  export function get<S extends Shape>(
    root: Form<S>,
    path: string[],
  ): Form<any> {
    return path.reduce((form: Form<any>, key, i) => {
      if (!form.children[key]) {
        throw new Error(
          `Invalid path to child form: ${path.slice(0, i).join(" -> ")}`,
        );
      }

      return form.children[key];
    }, root);
  }

  export function getPathFromChild(root: Form<any>, childPath: string[]) {
    return childPath.reduce((heritage: Child<any, any>[], key, i) => {
      const parent = heritage[0] || root;
      if (!parent.children[key]) {
        throw new Error(
          `Invalid path to child form: ${childPath.slice(0, i).join(" -> ")}`,
        );
      }

      return [parent.children[key], ...heritage];
    }, []);
  }

  export function remove<F extends Form<any>>(form: F, path: string[]): F {
    if (path.length < 1) {
      return form;
    }

    if (path.length === 1) {
      const { [path[0]]: _, ...children } = form.children;
      return {
        ...form,
        children,
      };
    }

    const next = form.children[path[0]];
    if (!next) {
      // parent of this form was already removed
      return form;
    }

    return {
      ...form,
      children: {
        ...form.children,
        [path[0]]: remove(next, path.slice(1)),
      },
    };
  }

  export function set<F extends Form<any>>(
    form: F,
    path: string[],
    next: F,
  ): F {
    const [step, ...subPath] = path;
    if (step === undefined) {
      return next;
    }

    if (!isChild(next)) {
      throw new Error(`Invalid child state: ${path.join(" -> ")}`);
    }

    return {
      ...form,
      children: {
        ...form.children,
        [step]: set(form.children[step], subPath, next),
      },
    };
  }

  function isChild<S extends Shape>(form: Form<S>): form is Child<S, any> {
    return (
      "read" in form &&
      typeof form.read === "function" &&
      "write" in form &&
      typeof form.write === "function"
    );
  }

  export function getField<S extends Shape>(form: Form<S>, name: string) {
    const field = form.fields[name];
    if (!field) {
      throw new Error(`Invalid field: ${name}`);
    }
    return field;
  }

  export function setField<S extends Shape, F extends Form<S>>(
    form: F,
    key: Key<S>,
    newField: Field<any>,
  ): F {
    const fields: Form.Fields<S> = { ...form.fields };
    fields[key] = newField;
    return { ...form, fields };
  }

  export function getChild<PS extends Shape, CS extends Shape>(
    parent: Form<PS>,
    key: string,
    childSchema: Schema<CS>,
  ): Child<CS, PS> | undefined {
    const child = parent.children[key];
    if (child) {
      if (child.schema !== childSchema) {
        throw new Error(
          `Child form already exists with different schema: ${key}`,
        );
      }

      return child;
    }
  }

  export function keys<S extends Shape>(form: Form<S>): Key<S>[] {
    return Schema.keys(form.schema);
  }

  export function mapFields<S extends Shape, F extends Form<S>>(
    form: F,
    fn: (field: Field<any>, key: Key<S>) => Field<any>,
  ): F {
    return {
      ...form,
      fields: Object.fromEntries(
        (
          Object.entries(form.fields) as Array<[Key<S> & string, Field<Key<S>>]>
        ).map(([key, field]) => [key, fn(field, key)]),
      ),
    };
  }

  function mapChildren<F extends Form<any>>(
    form: F,
    fn: (child: Child<any, any>, key: string) => Child<any, any>,
  ): F {
    return {
      ...form,
      children: Object.fromEntries(
        Object.entries(form.children).map(([key, child]) => [
          key,
          fn(child, key),
        ]),
      ),
    };
  }

  export function initial<S extends Shape, PS extends Shape>(
    schema: Schema<S>,
    childOpts: Pick<Child<S, PS>, "read" | "write">,
  ): Child<S, PS>;
  export function initial<S extends Shape>(
    schema: Schema<S>,
    childOpts?: undefined,
  ): Form<S>;
  export function initial<S extends Shape, PS extends Shape>(
    schema: Schema<S>,
    childOpts?: Pick<Child<S, PS>, "read" | "write">,
  ): unknown {
    const state: Form<S> = {
      valid: undefined,
      schema,
      fields: Schema.initialFields(schema),
      children: {},
    };

    return !childOpts
      ? state
      : {
          ...state,
          ...childOpts,
        };
  }

  /**
   * Rebuild the values in all child forms by going to each child, using
   * its `read()` function to read from the parent form, then using those
   * as the new values for all the child forms fields. Once the child
   * is rebuilt apply the same logic to all of its children recursively.
   */
  export function refreshChildren<F extends Form<any>>(parent: F): F {
    return mapChildren(parent, (child) =>
      refreshChildren(
        update(child, fillDefaults(child.schema, child.read(parent)), {
          ignoreExistingValues: true,
        }),
      ),
    );
  }

  /**
   * Apply an update to a form directly, not concerned with children or heirarchy.
   */
  export function update<S extends Shape, F extends Form<S>>(
    form: F,
    update: Update<S>,
    opts?: { ignoreExistingValues?: boolean },
  ): F {
    let valid = true;
    const nextFields = {} as Form.Fields<any>;
    const validValue = {} as Record<string, any>;

    for (const key of Schema.keys(form.schema)) {
      const current = form.fields[key];
      const schema = Schema.get(form.schema, key);
      let value =
        opts?.ignoreExistingValues || Object.hasOwn(update, key)
          ? update[key]
          : current.value;

      const result = schema.safeParse(value);
      const next: Field<Value<S, typeof key>> = {
        ...current,
        ...Field.updatedStatus(current),
        value: result.success ? result.data : value,
        error: result.success
          ? undefined
          : formatZodIssue(result.error.issues[0]),
      };

      nextFields[key] = next;
      validValue[key] = next.value;
      if (Field.isInvalid(next)) {
        valid = false;
      }
    }

    const effect = Schema.getEffect(form.schema);
    const rootLevelErrors: string[] = [];
    if (effect) {
      const outerParse = effect.safeParse(validValue);
      if (!outerParse.success) {
        valid = false;
        const erroredKeys = new Set<Key<S>>();
        for (const issue of outerParse.error.issues) {
          const key = issue.path[0] as Key<S>;
          if (Field.isInvalid(nextFields[key]) || erroredKeys.has(key)) {
            continue;
          }

          erroredKeys.add(key);
          nextFields[key] = {
            ...nextFields[key],
            error: formatZodIssue({
              ...issue,
              // remove the root key, otherwise the error is redundant
              path: issue.path.slice(1),
            }),
            ...Field.invalidEffectStatus(nextFields[key]),
          };
        }
      }
    }

    if (rootLevelErrors.length) {
      // TODO: we should probably render these in the UI, but I'm not sure
      // what to do about child forms... Maybe the root form can pull the
      // root-level errors from all of its children?
      console.error(
        "form errors which are not attributed to specific fields:",
        rootLevelErrors,
      );
    }

    const newState: F = {
      ...form,
      fields: nextFields,
      valid: valid ? validValue : undefined,
      children: form.children,
      rootLevelErrors: rootLevelErrors.length ? rootLevelErrors : undefined,
    };

    return newState;
  }

  /**
   * Forces all the fields in the form to become "hot", meaning they
   * will immediately show their errors
   */
  export function stopHidingErrorsDeep<F extends Form<any>>(form: F): F {
    return mapChildren(
      mapFields(form, (f) => ({
        ...f,
        hot: true,
      })),
      stopHidingErrorsDeep,
    );
  }

  /**
   * A form is only valid if all of it and all of its children are valid.
   */
  export function isValid(form: Form<any>): boolean {
    if (form.valid === undefined) {
      return false;
    }

    return Object.values(form.children).every(isValid);
  }

  /**
   * A form only appears valid if all of its fields are valid or have
   * their errors hidden, and all of its children appear valid.
   */
  export function appearsValid(form: Form<any>): boolean {
    for (const field of Object.values(form.fields)) {
      if (Field.appearsInvalid(field)) {
        return false;
      }
    }

    return Object.values(form.children).every(appearsValid);
  }

  function childKey(prefix: string, num: number): string {
    if (!prefix) {
      return `child-${num}`;
    }

    return num > 0 ? `${prefix}-${num}` : prefix;
  }

  export function nextChildKey(form: Form<any>, prefix: string) {
    let num = 0;

    while (Object.hasOwn(form.children, childKey(prefix, num))) {
      num += 1;
    }

    return childKey(prefix, num);
  }

  /**
   * The record of fields tracked by each form
   */
  export type Fields<S extends Shape> = Record<Key<S>, Field<any>>;

  /**
   * The state tracked for each individual field
   */
  export interface Field<V> extends Field.Status {
    readonly value: V | undefined;
    readonly error: string | undefined;
  }
  export namespace Field {
    export interface Status {
      /**
       * True if the field is currently focused
       */
      readonly focused: boolean;
      /**
       * True if the field has ever received an update while the field was
       * focused, false otherwise.
       */
      readonly updated: boolean;
      /**
       * True if the field was left in an invalid state after an update. Hot
       * fields have their errors shown immediately.
       */
      readonly hot: boolean;
    }

    export const isValid = (f: Field<any>) => f.error === undefined;
    export const isInvalid = (f: Field<any>) => f.error !== undefined;
    export const appearsInvalid = (f: Field<any>) =>
      showsErrors(f) ? isInvalid(f) : false;
    export const showsErrors = (f: Field<any>) => f.hot;

    /**
     * persist hot status, otherwiise transition to "touched"
     */
    export function focusStatus(f: Field<any>): Partial<Status> {
      return {
        focused: true,
      };
    }

    export function updatedStatus(f: Field<any>): Partial<Status> {
      return {
        updated: f.updated || f.focused,
      };
    }

    /**
     * on leaving a form field set the status to hot if the field
     * is invalid and the field is already hot, or the user actually
     * updated the field while focused on it
     */
    export function blurStatus(f: Field<any>): Partial<Status> {
      const invalid = isInvalid(f);
      return {
        focused: false,
        hot: invalid && (f.updated || f.hot),
      };
    }

    export function invalidEffectStatus(f: Field<any>): Partial<Status> {
      return {
        // make the field hot if the field is not focused but has
        // been updated, otherwise maintain it's hot-ness
        hot: f.hot || (!f.focused && f.updated),
      };
    }
  }

  /**
   * The Zod schema for the form
   */
  export type Schema<S extends Shape> =
    | z.ZodEffects<z.ZodObject<S>>
    | z.ZodObject<S>;
  export namespace Schema {
    function getShape<S extends Shape>(s: Schema<S>) {
      return (s instanceof z.ZodEffects ? s.innerType() : s).shape;
    }

    export function keys<S extends Shape>(s: Schema<S>): Key<S>[] {
      return Object.keys(getShape(s)) as Key<S>[];
    }

    export function get<S extends Shape>(
      s: Schema<S>,
      key: Key<S>,
    ): z.ZodSchema {
      return getShape(s)[key];
    }

    export function getEffect<S extends Shape>(
      s: Schema<S>,
    ): z.ZodEffects<any> | undefined {
      return s instanceof z.ZodEffects ? s : undefined;
    }

    export function initialFields<S extends Shape>(s: Schema<S>) {
      return Object.fromEntries(
        keys(s).map((key) => {
          const field: Form.Field<any> = {
            focused: false,
            updated: false,
            hot: false,
            error: undefined,
            value: undefined,
          };

          return [key, field];
        }),
      ) as Form.Fields<S>;
    }
  }
}
