import React from "react";

import { twMerge } from "../../twMerge";
import { Icon, IconName } from "../Icon";

import { Label } from "../Typography";

import styles from "./InputContainer.module.css";

import { HelpCircleTooltip } from "../HelpCircleTooltip";
import { Tooltip } from "../Tooltip";

export type InputContainerProps = React.PropsWithChildren<{
  className?: string;
  name?: string;
  tooltip?: string;
  disabled?: boolean;
  size?: "regular" | "large";
  error?: string | boolean;
  success?: boolean;
  warning?: React.ReactNode;
  shortWarning?: string;
  leftIcon?: IconName;
  prefix?: string;
  suffix?: string;
  "data-testid"?: string;
}>;

export const InputContainer: React.FC<InputContainerProps> = (props) => {
  const size = props.size || "regular";
  const disabled = !!props.disabled;
  const success =
    props.success &&
    !props.warning &&
    !props.shortWarning &&
    !props.error &&
    !disabled;
  const warning = props.warning || props.shortWarning;
  const rightIcon = props.error
    ? "alertCircle"
    : warning
      ? "warning"
      : props.success
        ? "checkmarkCircle"
        : undefined;

  return (
    <Tooltip content={!props.error && warning}>
      <div
        className={twMerge(
          styles.container,
          size === "large" ? styles.large : null,
          disabled ? styles.disabledState : null,
          props.error ? styles.errorState : null,
          !props.error && warning ? styles.warningState : null,
          success ? styles.successState : null,
          props.leftIcon ? styles.hasLeftIcon : null,
          rightIcon ? styles.hasRightIcon : null,
          props.prefix && props.prefix.length
            ? [
                styles.hasPrefix,
                styles[`hasPrefix${props.prefix?.length ?? ""}`],
              ]
            : null,
          props.className,
        )}
        data-testid={props["data-testid"]}
      >
        <Label>
          {props.name && (
            <div className={styles.inputHeader}>
              {props.name}
              {props.tooltip && <HelpCircleTooltip content={props.tooltip} />}
            </div>
          )}
          <div className={styles.inputWrapper}>
            {props.children}
            {!!props.prefix &&
              !!props.prefix.length && ( // Although odd, it's important that the prefix and the icons come _after_ the children, so we can style them based on the children content using the sibling selector
                <span className={styles.prefix}>{props.prefix}</span>
              )}
            {props.leftIcon && (
              <Icon
                icon={props.leftIcon}
                className={twMerge(styles.icon, styles.leftIcon)}
              />
            )}
            {!!props.suffix && (
              <span className={styles.suffix}>{props.suffix}</span>
            )}
            {rightIcon && (
              <Icon
                icon={rightIcon}
                className={twMerge(styles.icon, styles.rightIcon)}
              />
            )}
          </div>
          {!!props.error && typeof props.error === "string" && (
            <div className={styles.error}>{props.error}</div>
          )}
          {!props.error && !!props.shortWarning && (
            <div className={styles.warningLabel}>{props.shortWarning}</div>
          )}
        </Label>
      </div>
    </Tooltip>
  );
};
