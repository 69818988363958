import React from "react";
import { CorrectionOfFragment } from "./fragments.graphql";
import { Label, Subtitle } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { renderDate } from "../../../../lib/time";
import { InternalLink } from "../../../Typography";
import { dayjs } from "../../../../lib/dayjs";
import { useOptionalParam } from "../../../../lib/routes/params";
import { RefundOfFragment } from "components/Invoice/queries.graphql";

export const CorrectionOf: React.FC<{
  correction_of?: CorrectionOfFragment;
  refund_of?: RefundOfFragment;
  customer: { id: string; name: string };
}> = ({ correction_of, refund_of, customer }) => {
  const customerPlanId = useOptionalParam("customerPlanId");
  const contractId = useOptionalParam("contractId");

  if (!correction_of && !refund_of) {
    return null;
  }

  const correctionRecord =
    correction_of?.correction_record ?? refund_of?.refund_correction_record;

  if (!correctionRecord) {
    return null;
  }

  const originalInvoice = correctionRecord.original_invoice;

  const correctionRoutePath = `/customers/${customer.id}${
    customerPlanId
      ? `/plans/${customerPlanId}/`
      : contractId
        ? `/contracts/${contractId}/`
        : "/"
  }invoices/${originalInvoice.id}`;

  return (
    <div className="px-12 pt-12">
      <div className="flex items-center gap-4">
        <Subtitle level={1}>
          Invoice correction for{" "}
          <InternalLink routePath={correctionRoutePath}>
            {`${customer.name} ${renderDate(
              originalInvoice.__typename === "ContractScheduledInvoice" ||
                originalInvoice.__typename === "ContractPostpaidTrueupInvoice"
                ? new Date(originalInvoice.issued_at)
                : dayjs
                    .utc(originalInvoice.exclusive_end_date)
                    .subtract(1, "s")
                    .toDate(),
              {
                isUtc: true,
                excludeUtcLabel: true,
              },
            )}`}
          </InternalLink>
        </Subtitle>
        <IconButton
          className="mb-[1px] h-[12px] w-[12px]"
          theme="tertiary"
          icon="share04"
          linkTo={correctionRoutePath}
        />
      </div>
      <div>
        <Label className="text-xs">
          {correctionRecord.reason}{" "}
          {correctionRecord.memo ? `/ ${correctionRecord.memo}` : ""}
        </Label>
      </div>
    </div>
  );
};
