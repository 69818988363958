import React from "react";

import {
  LineItemFieldsFragment,
  SubLineItemFieldsFragment,
} from "../../fragments.graphql";

import { Headline } from "design-system";
import Decimal from "decimal.js";
import { ProductContainer } from "components/ProductContainer";
import { NoSubLineItems } from "../../../NoSubLineItems";
import { dayjs } from "lib/dayjs";
import { SubLineItems } from "../SubLineItems";
import { LineItemGroupHeading } from "../GroupedProductChargeLineItems";
import { EmbeddableDashboardContext } from "lib/embeddableDashboardContext";

export const ProductChargeLineItem: React.FC<{
  invoiceId: string;
  lineItem: LineItemFieldsFragment & {
    __typename:
      | "GroupedProductChargeLineItem"
      | "ProductChargeLineItem"
      | "LegacyContractLineItem"
      | "LegacyLineItem";
  };
  showChargesWithZeroUsage: boolean;
}> = ({ lineItem, showChargesWithZeroUsage, invoiceId }) => {
  function formatDate(startDate: string, endDate: string) {
    const actualStart = dayjs.utc(startDate);
    // display end date inclusive
    const actualEnd = dayjs.utc(endDate).subtract(1, "day");
    const template =
      actualStart.year() !== actualEnd.year() ? "MMMM D, YYYY" : "MMMM D";

    return `${actualStart.format(template)} - ${actualEnd.format(template)}`;
  }

  const lineItemName =
    lineItem.__typename === "GroupedProductChargeLineItem"
      ? lineItem.product.name
      : lineItem.display_name;

  const subLineItems =
    lineItem.__typename === "LegacyContractLineItem" ||
    lineItem.__typename === "LegacyLineItem"
      ? [lineItem]
      : lineItem.sub_line_items
          .map((subLineItem) => {
            if (
              (subLineItem.__typename === "ChargeLineItem" ||
                subLineItem.__typename === "AdvanceChargeLineItem") &&
              subLineItem.sub_line_items?.length
            ) {
              return {
                ...subLineItem,
                subRows:
                  subLineItem.sub_line_items as SubLineItemFieldsFragment[],
              };
            }

            if (subLineItem.__typename === "SeatChargeLineItem") {
              return {
                ...subLineItem,
                unit_price: subLineItem.seat_price,
                display_name: `${subLineItem.display_name} (${formatDate(
                  subLineItem.applicable_start_date,
                  subLineItem.applicable_end_date,
                )}) ${subLineItem.is_prorated ? " (Prorated)" : ""}`,
              };
            }
            return subLineItem;
          })
          .filter((subLineItem) => {
            if (showChargesWithZeroUsage) {
              return true;
            }
            if (
              subLineItem.__typename === "ChargeLineItem" ||
              subLineItem.__typename === "AdvanceChargeLineItem" ||
              subLineItem.__typename === "CompositeChargeLineItem" ||
              subLineItem.__typename === "SeatChargeLineItem"
            ) {
              return !new Decimal(subLineItem.quantity).eq(0);
            }
          });

  const {
    isEmbeddableDashboard,
    getGroupDisplayName,
    getGroupValueDisplayName,
  } = EmbeddableDashboardContext.useContainer();
  return (
    <div className="p-12">
      <ProductContainer
        title={
          <Headline
            level={5}
            className={
              isEmbeddableDashboard ? "text-default-font" : "text-primary-dark"
            }
          >
            {lineItemName}
          </Headline>
        }
        groupKey={
          lineItem.__typename === "GroupedProductChargeLineItem"
            ? getGroupDisplayName(lineItem.group_key)
            : null
        }
      >
        {lineItem.__typename === "GroupedProductChargeLineItem" && (
          <LineItemGroupHeading
            groupKey={getGroupDisplayName(lineItem.group_key)}
            groupValue={
              getGroupValueDisplayName(
                lineItem.group_key,
                lineItem.group_value,
              ) ?? "<empty>"
            }
          />
        )}
        <div className="-mb-12  p-12 pb-0">
          {subLineItems.length > 0 ? (
            <SubLineItems invoiceId={invoiceId} subLineItems={subLineItems} />
          ) : (
            <NoSubLineItems
              showChargesWithZeroUsage={showChargesWithZeroUsage}
            />
          )}
        </div>
      </ProductContainer>
    </div>
  );
};
