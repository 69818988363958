import React from "react";
import { Subtitle } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { renderDate } from "../../../../lib/time";
import { InternalLink } from "../../../Typography";
import { useOptionalParam } from "../../../../lib/routes/params";

export const Regenerated: React.FC<{
  customer: { id: string; name: string };
  issueDate: Date;
  regeneratedType: "from" | "to";
  regeneratedInvoiceId: string;
}> = ({ customer, issueDate, regeneratedType, regeneratedInvoiceId }) => {
  const contractId = useOptionalParam("contractId");

  if (!contractId) {
    return null;
  }

  const linkPath = `/customers/${customer.id}${
    contractId ? `/contracts/${contractId}` : ""
  }/invoices/${regeneratedInvoiceId}`;

  return (
    <div className="px-12 pt-12">
      <div className="flex items-center gap-4">
        <Subtitle level={1}>
          Invoice regenerated {regeneratedType}{" "}
          <InternalLink routePath={linkPath}>
            {`${customer.name} ${renderDate(issueDate, {
              isUtc: true,
              excludeUtcLabel: true,
            })}`}
          </InternalLink>
        </Subtitle>
        <IconButton
          className="mb-[1px] h-[12px] w-[12px]"
          theme="tertiary"
          icon="share04"
          linkTo={linkPath}
        />
      </div>
    </div>
  );
};
