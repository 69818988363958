import { Product } from "types/generated-graphql/__types__";
import { NonContractLineItem } from "../components/LineItem";
import { LineItemFieldsFragment } from "../queries.graphql";

export type GroupedProductChargeLineItemFragment = LineItemFieldsFragment & {
  __typename: "GroupedProductChargeLineItem";
};
export type ProductChargeLineItemFragment = LineItemFieldsFragment & {
  __typename: "ProductChargeLineItem";
};
type NonGroupedProductChargeLineItem = Exclude<
  NonContractLineItem,
  GroupedProductChargeLineItemFragment
>;

/**
 * From line items, extract unique list of products with order preserved
 * (first occurence of product wins).
 */
export function extractUniqueProducts(
  lineItems: NonContractLineItem[],
): Pick<Product, "id" | "name">[] {
  const seenProductIds = new Set<string>();
  const products = [];

  for (const lineItem of lineItems) {
    if (
      isGroupedProductLineItem(lineItem) &&
      !seenProductIds.has(lineItem.product.id)
    ) {
      products.push(lineItem.product);
      seenProductIds.add(lineItem.product.id);
    }
  }

  return products;
}

/**
 * Split line items into two groups: GroupedProductChargeLineItem's and then everything else.
 */
export function extractGroupedProductChargeLineItems(
  lineItems: NonContractLineItem[],
): {
  otherLineItems: NonGroupedProductChargeLineItem[];
  groupedProductChargeLineItems: GroupedProductChargeLineItemFragment[];
} {
  const otherLineItems: NonGroupedProductChargeLineItem[] = [];
  const groupedProductChargeLineItems: GroupedProductChargeLineItemFragment[] =
    [];

  for (const lineItem of lineItems) {
    if (!isGroupedProductLineItem(lineItem)) {
      otherLineItems.push(lineItem);
    } else {
      groupedProductChargeLineItems.push(lineItem);
    }
  }

  return {
    otherLineItems,
    groupedProductChargeLineItems,
  };
}

/**
 * Create map of productId to GroupedProductChargeLineItem's
 */
export function groupLineItemsByProduct(
  lineItems: GroupedProductChargeLineItemFragment[],
): Map<string, GroupedProductChargeLineItemFragment[]> {
  const productIdToLineItemsMap = new Map<
    string,
    GroupedProductChargeLineItemFragment[]
  >();

  for (const lineItem of lineItems) {
    const lineItems = productIdToLineItemsMap.get(lineItem.product.id) ?? [];
    productIdToLineItemsMap.set(lineItem.product.id, [...lineItems, lineItem]);
  }

  return productIdToLineItemsMap;
}

function isGroupedProductLineItem(
  lineItem: NonContractLineItem,
): lineItem is GroupedProductChargeLineItemFragment {
  return lineItem.__typename === "GroupedProductChargeLineItem";
}
