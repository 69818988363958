import { ContractLineItem } from "../components/LineItem/types";
import { RecordMap } from "@metronome-industries/record-utils";

export type PresentationGroupValues = Record<string, string | null>;

export const isUngrouped = (lineItem: ContractLineItem) =>
  !(
    lineItem.__typename === "ContractUsageLineItem" &&
    (lineItem.presentation_group_values ?? []).length > 0
  );

export const groupLineItems = (lineItems: ContractLineItem[]) => {
  const groups = new RecordMap<PresentationGroupValues, ContractLineItem[]>();
  const ungroupedLineItems = lineItems.filter((lineItem) =>
    isUngrouped(lineItem),
  );

  for (const lineItem of lineItems) {
    if (lineItem.__typename !== "ContractUsageLineItem") {
      continue;
    }
    const groupValuesArr = lineItem.presentation_group_values;
    if (!groupValuesArr || groupValuesArr.length === 0) {
      continue;
    }
    const presentationGroupValues = Object.fromEntries(
      groupValuesArr.map(({ name, value }) => [name, value]),
    );
    const group = groups.get(presentationGroupValues);
    if (group) {
      group.push(lineItem);
    } else {
      groups.set(presentationGroupValues, [lineItem]);
    }
  }
  const groupedLineItems = Array.from(groups.values());

  if (ungroupedLineItems.length === 0) {
    return groupedLineItems;
  }
  return [...groupedLineItems, ungroupedLineItems];
};
