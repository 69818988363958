import React from "react";
import { Dayjs, dayjs } from "lib/dayjs";
import {
  useMatches,
  useNavigationType,
  NavigationType,
  useInRouterContext,
} from "react-router-dom";

import { NowManipulator } from "./NowManipulator";

const NowContext = React.createContext<Dayjs | null>(null);

/**
 * get a date value representing a relatively stable version
 * of `now` for a component to use.
 */
export function useNow() {
  const now = React.useContext(NowContext);
  if (!now) {
    throw new Error("useNow() must be used within a <NowProvider> component");
  }

  return now;
}

const NowProvider__Fixed: React.FC<
  React.PropsWithChildren<{ value: string }>
> = ({ value, children }) => {
  const now = React.useMemo(() => dayjs.utc(value), [value]);
  return <NowContext.Provider value={now}>{children}</NowContext.Provider>;
};

const NowProvider__TickOnRouteChange: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [override, setOverride] = React.useState<string>();
  const matches = useMatches();
  const navType = useNavigationType();
  const auto = React.useRef<{ matches: unknown; dayjs: Dayjs }>();

  if (
    !auto.current ||
    // if routes change but because replace was used then don't update the now value
    // this happens on pages like the invoices page, where onces the invoices are loaded
    // the route changes to select the first invoice, which could cause a rerender and
    // potentially unnecessary data fetching because the `now` value is different
    (auto.current.matches !== matches && navType !== NavigationType.Replace)
  ) {
    auto.current = { matches, dayjs: dayjs.utc() };
  }

  const overrideDayjs = React.useMemo(
    () => (override ? dayjs.utc(override) : null),
    [override],
  );

  const now = overrideDayjs?.isValid() ? overrideDayjs : auto.current.dayjs;

  return (
    <NowContext.Provider value={now}>
      <NowManipulator now={now} override={setOverride}>
        {children}
      </NowManipulator>
    </NowContext.Provider>
  );
};

interface NowProps extends React.PropsWithChildren {
  fixed?: string;
}

export const NowProvider: React.FC<NowProps> = ({ fixed, children }) => {
  const inRouterContext = useInRouterContext();
  if (!inRouterContext && !fixed) {
    throw new Error(
      "NowProvider must be used within a <Router> component or provided with a fixed value",
    );
  }

  return fixed ? (
    <NowProvider__Fixed value={fixed}>{children}</NowProvider__Fixed>
  ) : (
    <NowProvider__TickOnRouteChange>{children}</NowProvider__TickOnRouteChange>
  );
};
