import React from "react";

import { twMerge } from "../../twMerge";
import styles from "./index.module.css";
import * as Icons from "ionicons/icons";

export type IconName = keyof typeof Icons;

interface IconProps {
  icon: IconName;
  mirror?: "horizontal" | "vertical";
  className?: string;
  "data-testid"?: string;
}

export const Icon: React.FC<IconProps> = (props: IconProps) => {
  const icon = Icons[props.icon].replace("data:image/svg+xml;utf8,", "");
  return (
    <div
      className={twMerge(
        styles.icon,
        "inline-flex h-[1em] w-[1em]",
        props.mirror === "horizontal" && "-scale-x-100",
        props.mirror === "vertical" && "-scale-y-100",
        props.className,
      )}
      data-testid={props["data-testid"]}
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  );
};
