import { deepEqual } from "fast-equals";

import { Form } from "./Form";

export type ReaderFor<S extends Form.Schema<any>> =
  S extends Form.Schema<infer X> ? FormReader<X> : never;

export type UnvalidatedInput<C extends FormReader<any>> =
  C extends FormReader<infer X> ? { [K in Form.Key<X>]: unknown } : never;

export class FormReader<S extends Form.Shape> {
  constructor(public readonly state: Form<S>) {}

  snapshot(): unknown {
    return JSON.parse(JSON.stringify(this.getUnvalidatedInputs()));
  }

  matchesSnapshot(snapshot: unknown) {
    return deepEqual(snapshot, this.snapshot());
  }

  /**
   * If the form is valid, return the validated value, otherwise undefined
   */
  getValid(): Form.Values<S> | undefined {
    return this.state.valid;
  }

  /**
   * Returns true if the user has updated the field, otherwise false.
   */
  userUpdated(key: Form.Key<S>) {
    return this.state.fields[key].updated;
  }

  /**
   * "Protected" so it exists for JSON.stringify(), but doesn't show up on the public interface
   */
  protected toJSON(): unknown {
    const children = Object.fromEntries(
      Object.entries(this.state.children).map(([key, child]) => [
        key,
        new FormReader(child),
      ]),
    );

    return JSON.parse(
      JSON.stringify({
        valid: this.state.valid,
        fields: this.state.fields,
        rootLevelErrors: this.state.rootLevelErrors,
        children: Object.keys(children).length ? children : undefined,
      }),
    );
  }

  /**
   * Get the current value of a specific field.
   */
  get<K extends Form.Key<S>>(key: K): Form.Value<S, K> | undefined {
    return this.state.fields[key].value;
  }

  /**
   * Returns true if the form is valid. Since most fields will be invalid when the
   * form first loads and we don't want to show them all immediately, use `appearsValid()`
   * instead in most cases.
   */
  isValid(key?: Form.Key<S>) {
    return key === undefined
      ? Form.isValid(this.state)
      : Form.Field.isValid(this.state.fields[key]);
  }

  /**
   * Returns true if the form is valid or if the invalid fields
   * have not been interacted with yet.
   */
  appearsValid() {
    return Form.appearsValid(this.state);
  }

  /**
   * Get all the current field values, without validation
   */
  getUnvalidatedInputs() {
    const values = {} as { [K in Form.Key<S>]: unknown };

    for (const key of Form.keys(this.state)) {
      values[key] = this.state.fields[key].value;
    }

    return values;
  }
}
