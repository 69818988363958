import { Form } from "./Form";
import { fillDefaults } from "./Zod";

interface InitAction {
  type: "INIT";
  defaults: Form.Update<any>;
}
interface InitChildAction {
  type: "INIT_CHILD";
  parentPath: string[];
  childKey: string;
  schema: Form.Schema<any>;
  read: (parent: Form<any>) => Form.Update<any>;
  write: (child: Form<any>, parent: Form<any>) => Form.Update<any>;
}
interface RemoveChildAction {
  type: "REMOVE_CHILD";
  path: string[];
}
interface UpdateAction {
  type: "UPDATE";
  path: string[];
  updates: { [key: string]: any };
}
interface FocusAction {
  type: "FOCUS";
  path: string[];
  entered: boolean;
  field: string;
}
interface InvalidSubmitAttemptedAction {
  type: "STOP_HIDING_ERRORS";
  path: string[];
}
export type FormAction =
  | InitAction
  | InitChildAction
  | RemoveChildAction
  | UpdateAction
  | FocusAction
  | InvalidSubmitAttemptedAction;

export function FormReducer<S extends Form.Shape>(
  root: Form<S>,
  action: FormAction,
): Form<S> {
  switch (action.type) {
    case "INIT": {
      const rootDefaults = fillDefaults(root.schema, action.defaults);
      const updated = Form.update(root, rootDefaults);
      return Form.refreshChildren(updated);
    }

    case "INIT_CHILD": {
      const { schema, read, parentPath, childKey, write } = action;
      const parent = Form.get(root, parentPath);
      if (parent.children[childKey]) {
        throw new Error("child already initialized with this key");
      }

      const path = [...parentPath, childKey];
      const withChild = Form.set(
        root,
        path,
        Form.initial(schema, { read, write }),
      );
      return FormReducer(withChild, {
        type: "UPDATE",
        path,
        updates: fillDefaults(schema, read(parent)),
      });
    }

    case "REMOVE_CHILD": {
      return Form.remove(root, action.path);
    }

    /**
     * If the user attempts to submit the form before all fields are valid
     * then we stop hiding errors in all form fields so that they know what
     * they need to fix.
     */
    case "STOP_HIDING_ERRORS": {
      return Form.set(
        root,
        action.path,
        Form.stopHidingErrorsDeep(Form.get(root, action.path)),
      );
    }

    /**
     * In order to improve the UX of forms we use focus state of fields to help
     * determine if we should show the user errors. This action helps us meet the
     * following goals:
     *  - Show field validation errors the user has updated a field, but not while they are editting it
     *  - Show field validation errors as the user edits the field while the user is attempting correct existing errors
     */
    case "FOCUS": {
      const { path, field: key, entered } = action;
      const form = Form.get(root, path);
      const field = Form.getField(form, key);

      if (entered && Form.Field.appearsInvalid(field)) {
        return root;
      }

      return Form.set(
        root,
        path,
        Form.setField(form, key, {
          ...field,
          ...(entered
            ? Form.Field.focusStatus(field)
            : Form.Field.blurStatus(field)),
        }),
      );
    }

    /**
     * Apply an update to a form, if the form is a child form then the update
     * will be transformed by calling the `write` function of the child forms
     * until reaching the root, then the root form will be updated and all
     * child forms will be derived by reading from their parent forms.
     */
    case "UPDATE": {
      const { path, updates } = action;
      const children = Form.getPathFromChild(root, path);

      // call the write function of each child form, starting at the leaf
      // form and traversing to the root, in order to transform the update
      // into a root-form update
      const rootUpdate = children.reduce(
        (update, form, i) =>
          form.write(Form.update(form, update), children[i + 1] ?? root),
        updates,
      );

      // apply the update to the root form
      const updatedRoot = Form.update(root, rootUpdate);

      // rebuild the child forms by reading from the parent
      return Form.refreshChildren(updatedRoot);
    }
  }
}
