import React from "react";

import { Form } from "./lib/Form";
import { FormAction, FormReducer } from "./lib/Reducer";
import { PropCreators } from "./lib/Props";
import { FormReader } from "./lib/FormReader";

export type ControllerFor<S extends Form.Schema<any>> =
  S extends Form.Schema<infer X> ? FormController<X> : never;

export type Dispatch = ((action: FormAction) => void) & {
  getUnpublishedState(): Form<any>;
  publishOnNextFrame(action: FormAction): Form<any>;
};

/**
 * A class for managing the validation, value, and error states of
 * a list of form fields. For more complete documentation see the
 * README.md file in this directory.
 */
export class FormController<S extends Form.Shape> extends FormReader<S> {
  props: PropCreators<S>;

  constructor(
    readonly state: Form<S>,
    readonly _rootState: Form<any>,
    readonly _path: string[],
    readonly _dispatch: Dispatch,
  ) {
    super(state);
    this.props = PropCreators.create(this);
  }

  /**
   * Apply an update to the values in the form
   */
  update(updates: Form.Update<S>) {
    this._dispatch({
      type: "UPDATE",
      path: this._path,
      updates,
    });
  }

  /**
   * To improve the user experience, we hide error messages for fields
   * until they have been interacted with, and whenever the field is left
   * in a valid state. This method allows you to disable this behavior
   * for all fields, perhaps because the form was submitted with errors.
   *
   * This method is recursive and will stop hiding all errors in this form
   * and all of its children.
   */
  stopHidingErrors() {
    this._dispatch({
      type: "STOP_HIDING_ERRORS",
      path: this._path,
    });
  }

  /**
   * When a form field is focused, turn on error message hiding for that
   * field if it was previously disabled and is not currently in an error
   * state. This allows the user to type in the field and potentially put
   * the field into an error state temporarily without flipping the error
   * back and forth.
   *
   * If the field is currently in an error state we want to make it clear
   * to the user when they have fixed the error, so we leave error message
   * hiding disabled for the most prompt feedback.
   */
  onFocus<K extends Form.Key<S>>(field: K) {
    this._dispatch({
      type: "FOCUS",
      entered: true,
      path: this._path,
      field,
    });
  }

  /**
   * When a form field is blurred, turn off error message hiding for that
   * field if the error is now in an error state and error message hiding
   * is currently enabled. This allows the user to see the error message
   * when they leave the field, without interupting them while they type.
   */
  onBlur<K extends Form.Key<S>>(field: K) {
    this._dispatch({
      type: "FOCUS",
      entered: false,
      path: this._path,
      field,
    });
  }
}

export class RootFormController<
  S extends Form.Shape,
> extends FormController<S> {
  constructor(
    state: Form<S>,
    dispatch: Dispatch,
    readonly _getDefaults: () => Form.Update<S> | undefined,
  ) {
    super(state, state, [], dispatch);
  }

  reset() {
    this._dispatch({
      type: "INIT",
      defaults: this._getDefaults() || {},
    });
  }
}

export namespace FormController {
  export interface RootFormOptions<
    S extends Form.Shape,
    InitArgs extends any[],
  > {
    init?(...extraArgs: InitArgs): Form.Update<S> | undefined;
  }

  /**
   * Create a hook that will return an instance of a FormController configured
   * for a specific schema. See lib/FormController/README.mdx for more info
   */
  export function createHook<S extends Form.Shape, A extends any[]>(
    schema: Form.Schema<S>,
    options?: RootFormOptions<S, A>,
  ) {
    function useFormController(...args: A) {
      const getDefaults = (): Form.Update<S> => options?.init?.(...args) || {};

      const initial = React.useMemo(() => {
        return FormReducer(Form.initial(schema), {
          type: "INIT",
          defaults: getDefaults(),
        });
      }, []);

      const [published, setPublished] = React.useState(initial);
      const state = React.useRef(initial);
      const dispatch = React.useMemo((): Dispatch => {
        function dispatch(action: FormAction) {
          state.current = FormReducer(state.current, action);
          setPublished(state.current);
          return state.current;
        }

        return Object.assign(dispatch, {
          getUnpublishedState() {
            return state.current;
          },
          publishOnNextFrame(action: FormAction) {
            state.current = FormReducer(state.current, action);
            window.requestAnimationFrame(() => setPublished(state.current));
            return state.current;
          },
        });
      }, []);

      return React.useMemo(() => {
        return new RootFormController(published, dispatch, getDefaults);
      }, [published, dispatch]);
    }

    return Object.assign(useFormController, {
      /**
       * Create a form controller which uses reads and writes it's
       * values to another form controller. This is useful for creating
       * nested forms.
       */
      child<CS extends Form.Shape, A extends any[]>(
        childSchema: Form.Schema<CS>,
        options: ChildOptions<S, CS, A>,
      ) {
        return makeChildHook(childSchema, options);
      },
    });
  }

  export interface ChildOptions<
    PS extends Form.Shape,
    CS extends Form.Shape,
    A extends any[],
  > {
    /**
     * for easier debugging, give the child a name and it will be used in the state tree
     */
    debugName?: string;
    read(parent: FormReader<PS>, ...extraArgs: A): Form.Update<CS> | undefined;
    write(
      child: FormReader<CS>,
      parent: FormReader<PS>,
      ...extraArgs: A
    ): Form.Update<PS> | undefined;
  }

  export function makeChildHook<
    PS extends Form.Shape,
    CS extends Form.Shape,
    A extends any[],
  >(schema: Form.Schema<CS>, options: ChildOptions<PS, CS, A>) {
    function useChildController(parent: FormController<PS>, ...extraArgs: A) {
      const extraArgsJson = JSON.stringify(extraArgs);
      const prefix = [options.debugName, extraArgs.length && extraArgsJson]
        .filter(Boolean)
        .join("-");

      // update the root state to include this child on the first render so that
      // subsequent renders will be able to find the child
      const { childKey, initialState } = React.useMemo(() => {
        const childKey = Form.nextChildKey(
          Form.get(parent._dispatch.getUnpublishedState(), parent._path),
          prefix,
        );

        // since this is called within a render we can't setState/trigger a
        // render in a parent component immediately, so we need to wait until
        // the next frame to publish the updated root state
        const updatedRoot = parent._dispatch.publishOnNextFrame({
          type: "INIT_CHILD",
          parentPath: parent._path,
          childKey,
          schema,
          read(parent) {
            return options.read(new FormReader(parent), ...extraArgs) ?? {};
          },
          write(child, parent) {
            return (
              options.write(
                new FormReader(child),
                new FormReader(parent),
                ...extraArgs,
              ) ?? {}
            );
          },
        });

        return {
          childKey,
          initialState: Form.get(updatedRoot, [...parent._path, childKey]),
        };
      }, [prefix]);

      // when the child key changes or the form controller is no longer mounted, clear the child state
      React.useEffect(() => {
        return () => {
          parent._dispatch({
            type: "REMOVE_CHILD",
            path: [...parent._path, childKey],
          });
        };
      }, [childKey]);

      return React.useMemo(() => {
        const child =
          Form.getChild(parent.state, childKey, schema) ?? initialState;

        return new FormController<CS>(
          child,
          parent.state,
          [...parent._path, childKey],
          parent._dispatch,
        );
      }, [parent, childKey, extraArgsJson]);
    }

    return Object.assign(useChildController, {
      /**
       * Create a form controller which uses reads and writes it's
       * values to another form controller. This is useful for creating
       * nested forms.
       */
      child<C2 extends Form.Shape, A2 extends any[]>(
        childSchema: Form.Schema<C2>,
        options: ChildOptions<CS, C2, A2>,
      ) {
        return makeChildHook(childSchema, options);
      },
    });
  }

  /**
   * Creates an onSubmit handler that will call the provided callback
   * with the validated values if the form is valid, otherwise it will
   * prevent the default form submission and show error messages.
   */
  export function useSubmitHandler<S extends Form.Shape>(
    ctrl: FormController<S>,
    withValid: (
      valid: Form.Values<S>,
      e?: React.FormEvent<HTMLFormElement> | React.MouseEvent,
    ) => void,
  ) {
    return (e?: React.FormEvent<HTMLFormElement> | React.MouseEvent) => {
      if (e) {
        if (e.defaultPrevented) {
          return;
        }

        e.preventDefault();
        e.stopPropagation();
      }

      const valid = ctrl.getValid();
      if (!valid) {
        ctrl.stopHidingErrors();
        return;
      }

      withValid(valid);
    };
  }

  /**
   * Parse a snapshot from some form controller into an update for
   * a form controller using the given Schema
   */
  export function parseJsonSnapshot<S extends Form.Shape>(
    schema: Form.Schema<S>,
    snapshotJson?: string | null,
  ): Form.Update<S> | undefined {
    if (!snapshotJson) {
      return;
    }

    try {
      const snapshot = JSON.parse(snapshotJson);
      if (typeof snapshot !== "object" || snapshot === null) {
        return;
      }

      const update: Form.Update<S> = {};
      for (const key of Form.Schema.keys(schema)) {
        if (!Object.hasOwn(snapshot, key)) {
          continue;
        }

        const value = (snapshot as any)[key];
        const parse = Form.Schema.get(schema, key).safeParse(value);
        if (parse.success) {
          update[key] = parse.data;
        }
      }

      return update;
    } catch (error) {
      console.error("Failed to parse JSON form snapshot", snapshotJson, error);
      return;
    }
  }
}
