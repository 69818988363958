function trimLeadingSlash(string: string) {
  return string.startsWith("/") ? string.slice(1) : string;
}

/**
 * A list of route prefixes that indicate the route path is not env-specific, meaning
 * we should not prefix it with the env slug. Prefixes are split on / and all segments
 * must match the begging of the route path for the route path to be considered "non-env".
 */
const NON_ENV_ROUTE_PREFIXES = [
  "account",
  "logout",
  "settings/team",
  "settings/general/integrations/data-export/new",
].map((prefix) => trimLeadingSlash(prefix).split("/"));

function matchAnyPrefix(segments: string[], prefixes: string[][]) {
  return prefixes.some((prefix) => {
    if (prefix.length > segments.length) {
      return false;
    }

    for (let i = 0; i < prefix.length; i++) {
      if (prefix[i] !== segments[i]) {
        return false;
      }
    }

    return true;
  });
}

export function shouldPrefixRoutePath(routePath: string, envSlug: string) {
  if (!routePath.startsWith("/")) {
    // routePath is not really a route path, might be a http:// or https:// url or just a relative path
    return false;
  }

  const routePathSegments = trimLeadingSlash(routePath).split("/");

  // route is already prefixed
  if (routePathSegments[0] === trimLeadingSlash(envSlug)) {
    return false;
  }

  // return true only if none of the non-env routes match the route path
  return !matchAnyPrefix(routePathSegments, NON_ENV_ROUTE_PREFIXES);
}
