import React from "react";
import { EmbeddableDashboardContext } from "lib/embeddableDashboardContext";
import { Tooltip } from "design-system";
import Decimal from "decimal.js";

export function Quantity({
  quantity,
  rounded,
}: {
  quantity: string | Decimal;
  rounded?: boolean;
}) {
  const { isEmbeddableDashboard } = EmbeddableDashboardContext.useContainer();
  const decimalQuantity = new Decimal(quantity);
  if (decimalQuantity.isNaN()) {
    return <>--</>;
  }

  const roundedValue = decimalQuantity.abs().lessThan(1)
    ? decimalQuantity.toSignificantDigits(2)
    : decimalQuantity.toDecimalPlaces(0);

  if (
    !roundedValue.equals(decimalQuantity) &&
    (isEmbeddableDashboard || rounded)
  ) {
    return (
      <span data-testid="tooltip">
        <Tooltip content={decimalQuantity.toString()}>
          {roundedValue.toString()}
        </Tooltip>
      </span>
    );
  } else {
    return <>{decimalQuantity.toString()}</>;
  }
}
