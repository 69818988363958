import * as React from "react";
import { Tooltip } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { PopConfirm } from "components/PopConfirm";

import { useDeleteOneTimeChargeMutation } from "./queries.graphql";

export const DeleteOneTimeChargeButton: React.FC<{
  id: string;
  invoiceId: string;
}> = ({ id, invoiceId }) => {
  const [deleteOneTimeCharge, { loading }] = useDeleteOneTimeChargeMutation({
    variables: { charge_id: id },
    update(cache) {
      cache.evict({ id: `ArrearsInvoice:${invoiceId}` });
    },
  });
  return (
    <Tooltip content="Remove one-time charge" inline>
      <PopConfirm
        content="Remove one-time charge?"
        onConfirm={deleteOneTimeCharge}
        position="right"
        type="error"
      >
        <IconButton
          disabled={loading}
          loading={loading}
          theme="tertiary"
          icon="trash03"
          size="sm"
        />
      </PopConfirm>
    </Tooltip>
  );
};
