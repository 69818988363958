import React from "react";

import { SubLineItemFieldsFragment } from "../fragments.graphql";

import Decimal from "decimal.js";
import { RoundedCurrency, USD_CREDIT_ID } from "lib/credits";
import { RowSpec, SimpleTable } from "components/SimpleTable";
import { TierChargeLineItem } from "types/generated-graphql/__types__";
import { DeleteOneTimeChargeButton } from "components/Invoice/components/DeleteOneTimeChargeButton";
import { Quantity } from "../Quantity";

export const SubLineItems: React.FC<{
  subLineItems: SubLineItemFieldsFragment[];
  invoiceId: string;
}> = ({ invoiceId, subLineItems }) => {
  return (
    <SubLineItemsTable
      invoiceId={invoiceId}
      rowSpecs={subLineItems.map((subLineItem) => ({
        type: "data",
        data: subLineItem,
      }))}
    />
  );
};

export const SubLineItemsTable: React.FC<{
  rowSpecs: RowSpec<SubLineItemFieldsFragment>[];
  invoiceId: string;
}> = ({ invoiceId, rowSpecs }) => {
  return (
    <SimpleTable
      noBottomBorder
      rowSpecs={rowSpecs}
      columns={[
        {
          header: "Description",
          render: (subLineItem) => {
            if (
              (subLineItem.__typename === "ChargeLineItem" ||
                subLineItem.__typename === "AdvanceChargeLineItem") &&
              subLineItem.one_time_charge?.id
            ) {
              return (
                <>
                  {subLineItem.display_name}
                  <DeleteOneTimeChargeButton
                    invoiceId={invoiceId}
                    id={subLineItem.one_time_charge.id}
                  />
                </>
              );
            }
            return subLineItem.display_name;
          },
        },
        {
          header: "Quantity",
          alignment: "right",
          render: (subLineItem) => {
            if (
              subLineItem.__typename === "ChargeLineItem" ||
              subLineItem.__typename === "AdvanceChargeLineItem" ||
              subLineItem.__typename === "SeatChargeLineItem" ||
              subLineItem.__typename === "CompositeChargeLineItem"
            ) {
              return <Quantity quantity={subLineItem.quantity} />;
            } else if (tierChargeLineItemTypeGuard(subLineItem)) {
              return (
                <Quantity
                  quantity={(subLineItem as TierChargeLineItem).quantity}
                />
              );
            }
            return "-";
          },
        },
        {
          header: "Unit Price",
          alignment: "right",
          render: (subLineItem) => {
            if (
              subLineItem.__typename === "ChargeLineItem" ||
              subLineItem.__typename === "AdvanceChargeLineItem"
            ) {
              if (!subLineItem.unit_price) {
                return "Tiered";
              }
              return (
                <RoundedCurrency
                  amount={new Decimal(subLineItem.unit_price)}
                  creditType={subLineItem.credit_type}
                />
              );
            } else if (subLineItem.__typename === "SeatChargeLineItem") {
              return (
                <RoundedCurrency
                  amount={new Decimal(subLineItem.seat_price)}
                  creditType={subLineItem.credit_type}
                />
              );
            } else if (subLineItem.__typename === "CompositeChargeLineItem") {
              const unitPrice =
                subLineItem.quantity === "0"
                  ? new Decimal(0)
                  : new Decimal(subLineItem.total).div(subLineItem.quantity);
              return (
                <RoundedCurrency
                  amount={unitPrice}
                  creditType={subLineItem.credit_type}
                />
              );
            } else if (tierChargeLineItemTypeGuard(subLineItem)) {
              return (
                <RoundedCurrency
                  amount={new Decimal(subLineItem.tier_price)}
                  creditType={subLineItem.credit_type}
                />
              );
            }
            return "-";
          },
        },
        {
          header: "Total",
          alignment: "right",
          render: (subLineItem) => {
            return (
              <RoundedCurrency
                amount={new Decimal(subLineItem.total)}
                creditType={subLineItem.credit_type}
                hideSuffix={subLineItem.credit_type.id === USD_CREDIT_ID}
              />
            );
          },
        },
      ]}
    />
  );
};

function tierChargeLineItemTypeGuard(
  t: SubLineItemFieldsFragment,
): t is TierChargeLineItem {
  return t.__typename === "TierChargeLineItem";
}
